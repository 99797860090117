import Layout, { MyContext } from "../components/layout"
import React, { useEffect, useRef, useContext } from "react"
import Swiper from "swiper"
import styled from "styled-components"
import xButtonImg from "../images/xx_icon.png"
import { SlidingViewer } from "../components/viewer/SlidingViewer"
import { device, deviceSize } from "../styles/device"

const Conti = {
  mainSrc: ``,
}

interface Movie {
  imgSrc: string
}

interface Conti {
  imgSrc: string
}

interface Props {
  imageList: Movie[]
  contiList?: Conti[]
  setShowViewer: any
}

const Viewer = () => {
  const { showViewer, setShowViewer, activeConti, setActiveConti } = useContext(
    MyContext
  )

  if (!activeConti) return null
  const { title, contiImages, mobileContiImages } = activeConti

  return (
    <Wrapper>
      <ViewerHeader>
        <ViewerHeaderTitle>{title}</ViewerHeaderTitle>
        <CloseButton
          onClick={() => {
            window.history.back();
          }}
        >
          <img src={xButtonImg} />
        </CloseButton>
      </ViewerHeader>
      <ContiViewer type="desktop" imageList={contiImages} />
      <ContiViewer type="mobile" imageList={mobileContiImages} />
      <SlidingViewer node={activeConti} />
    </Wrapper>
  )
}

const ContiViewer = ({ type, imageList }) => {
  const swiperInstance = useRef()
  const containerElem = useRef()

  useEffect(() => {
    swiperInstance.current = new Swiper(containerElem.current, {
      slidesPerView: 1,
      spaceBetween: 8,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    })
  }, [])

  return (
    <div className={`swiper-container ${type}`} ref={containerElem}>
      <div className="swiper-wrapper">
        {imageList.map((image, idx) => {
          return (
            <div key={`swiper-${idx}`} className="swiper-slide">
              <img src={image.fixed.src} />
            </div>
          )
        })}
      </div>
      <div className="swiper-pagination"></div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </div>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 2;
  height: 100%;

  @media ${device.mobileL} {
    overflow: auto;
  }

  .swiper-container {
    width: 100%;
    margin-bottom: 24px;
    max-width: ${deviceSize.laptop};

    &.desktop {
      display: none;
      @media ${device.mobileL} {
        display: block;
      }
    }

    &.mobile {
      margin-bottom: 16px;
      display: block;
      @media ${device.mobileL} {
        display: none;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
`

const ViewerHeader = styled.div`
  height: 20px;
  color: #eaeaea;
  opacity: 0.7;
  padding: 16px;
  max-width: ${deviceSize.laptop};
  margin: 0 auto;
  position: relative;

  @media ${device.mobileL} {
    height: 20px;
  }
`

const ViewerHeaderTitle = styled.span`
  top: 6px;
  position: relative;
  font-size: 16px;
`

const CloseButton = styled.div`
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;

  @media ${device.mobileL} {
    right: 14px;
    top: 12px;
  }

  img {
    width: 28px;
  }
`

export default Viewer
