import React, { useRef, useEffect } from "react"
import Swiper from "swiper"
import styled from "styled-components"

export const SlidingViewer = ({ node }) => {
  const { title, images } = node
  const swiperInstance = useRef()
  const containerElem = useRef()

  useEffect(() => {
    swiperInstance.current = new Swiper(containerElem.current, {
      slidesPerView: 4,
      spaceBetween: 8,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    })
    // swiperInstance.current?.autoplay.start()
  }, [])

  return (
    <Wrapper>
      <div className="swiper-container" ref={containerElem}>
        <div className="swiper-wrapper">
          {images && images.map((image, idx) => {
            return (
              <div key={`slide-${idx}`} className="swiper-slide">
                <img src={image.fluid.src} />
              </div>
            )
          })}
        </div>
      </div>
      >
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  img {
    width: 100%;
  }
`
